@import '../../variable.scss';

.dx-field {
   display: flex;
   text-align: left;
}

.dx-popup-normal {
   .dx-item.dx-toolbar-item.dx-toolbar-label {
      max-width: none !important;
      //The component created inline css dynamically so i used the important
   }
   .dx-popup-title .dx-toolbar-label .dx-item-content.dx-toolbar-item-content {
      font-family: Fira Sans;
      font-size: 28px;

      @media (max-width: $sm-width) {
         font-size: 22px;
      }
   }
   .dx-popup-title .dx-toolbar-center {
      @media (max-width: $sm-width) {
         margin-left: 0 !important;
      }
   }
}
.ml-auto {
   margin-left: auto;
}

.popup-meeting-creation {
   .col-xs-2 {
      display: flex;
      margin-left: auto;
   }

   .form-container {
      min-height: 300px;
   }

   .btn-primary {
      margin-left: 15px;
      width: 137px;
      height: 33px;
      background: #602626;
      color: white;
      border-color: #602626;
      font-family: Quasimoda;
      font-size: 14px;
      line-height: 17px;
      margin: 0 20px;

      &:hover {
         background: #602626;
         border-color: #602626;
      }

      @media (max-width: $sm-width) {
         margin-left: 20px;
         width: 110px;
      }
   }

   .formio-form {
      padding: 0px 20px 0px 20px;

      .formio-component-button.formio-component-submit {
         text-align: right;
      }

      .formio-component-textfield.formio-component-counselorId {
         display: none;
      }

      .formio-component-willStudentBeRequiredToProvideTheirStudentIdNumber {
         display: flex;

         @media (max-width: $sm-width) {
            flex-direction: column;
         }

         label {
            flex: 1;
         }
         .radio {
            flex: 1;
            display: flex;

            .form-check {
               width: 20%;
            }

            input {
               border: 1px solid #602626;
               background: #602626;
            }

            span {
               line-height: 157%;
            }
         }
      }
   }

   .hide-button {
      width: 137px;
      height: 33px;
      color: #602626;
      background-color: white;
      border-color: #602626;
      font-family: Quasimoda;
      font-size: 14px;
      line-height: 17px;
      margin-right: 20px;

      .dx-button-text {
         line-height: 0;
      }

      &:hover {
         color: #602626;
         background-color: white;
      }

      @media (max-width: $sm-width) {
         margin-left: 20px;
         width: 110px;
      }
   }
}

.formio-component-radio.questionnaire {
   align-items: flex-start;
   .form-radio.radio {
      flex-direction: column;
      width: 40%;

      @media (max-width: $sm-width) {
         width: 100%;
      }
   }
}
