@import '../../variable.scss';

.student-card-content {
   padding: 20px 30px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
   border-radius: 6px;
   margin-bottom: 25px;

   @media (max-width: $sm-width) {
      padding: 20px;
      margin-bottom: 15px;
   }

   a:hover {
      text-decoration: none;
   }

   h2 {
      margin-bottom: 20px;

      @media (max-width: $sm-width) {
         margin-bottom: 10px;
      }
   }

   p {
      @media (max-width: $sm-width) {
         margin-bottom: 5px;
      }
   }
}
