@import './variable.scss';
@import url('https://use.typekit.net/cgj1iab.css');
@import url('https://use.typekit.net/cgj1iab.css');

.App {
   height: 100vh;
}

@font-face {
   font-family: 'Founders Grotesk';
   src: local('Founders Grotesk'), url(./assets/fonts/FoundersGrotesk.ttc) format('truetype');
}

@font-face {
   font-family: 'Fira Sans';
   src: local('FiraSans-Regular'), url(./assets/fonts/FiraSans-Regular.ttf) format('truetype');
}

span,
p,
a,
li {
   font-family: Quasimoda;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 175%;

   @media (max-width: $sm-width) {
      font-size: 12px;
      margin-bottom: 5px;
   }
}

h1 {
   font-family: Founders Grotesk;
   font-style: normal;
   font-weight: 500;
   font-size: 36px;
   line-height: 43px;
   margin-bottom: 36px;
   color: $primary-color;

   @media (max-width: $sm-width) {
      margin-bottom: 15px;
      font-size: 28px;
   }
}

h2 {
   font-family: Fira Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 28px;
   line-height: 34px;
   color: $primary-color-dark;
   margin-bottom: 20px;

   @media (max-width: $sm-width) {
      font-size: 22px;
   }
}

h3 {
   font-family: Fira Sans;
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   line-height: 34px;
   color: $primary-color-dark;
   margin-bottom: 20px;

   @media (max-width: $sm-width) {
      font-size: 18px;
      margin-bottom: 15px;
   }
}
.btn-primary,
.primary-button {
   background: $primary-color-dark;
   border: 1px solid $primary-color-dark;
   border-radius: 4px;
   color: white;
}
.btn-primary:hover,
.primary-button:hover {
   background: $primary-color-dark;
   border: 1px solid $primary-color-dark;
   border-radius: 4px;
   color: white;
}

.secondary-button {
   background: #fff;
   border: 1px solid $primary-color-dark;
   border-radius: 4px;
   color: $primary-color-dark;
}

.secondary-button:hover {
   background: #fff;
   border: 1px solid $primary-color-dark;
   border-radius: 4px;
   color: $primary-color-dark;
}

.disabled-button {
   background: #cdcdcd;
   border-radius: 4px;
   color: $black-color-light;
}

.dx-radiobutton-icon.dx-radiobutton-icon-checked:before {
   border: 1px solid $primary-color-dark;
}

.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
   background: $primary-color-dark;
}

.dx-radiobutton.dx-state-focused:not(.dx-state-active) .dx-radiobutton-icon:before {
   border: 1px solid $primary-color-dark;
}

.dx-overlay-wrapper input,
.dx-overlay-wrapper textarea {
   border: 1px solid #cdcdcd;
}

.dx-popup-normal {
   .dx-popup-title {
      border-bottom: none;
      color: $primary-color-dark;

      .dx-item-content.dx-toolbar-item-content {
         font-family: Fira Sans;
         font-style: normal;
         font-weight: normal;
         font-size: 28px;
         line-height: 34px;
      }
   }

   .dx-popup-title.dx-toolbar {
      padding: 20px 40px;

      @media (max-width: $sm-width) {
         padding: 20px 20px;
      }
   }

   .dx-popup-content {
      @media (max-width: $sm-width) {
         padding: 0 0 20px 0;
      }
   }

   .dx-fieldset {
      margin-top: 0;
   }
}

.formio-component {
   .has-success {
      display: none;
   }
}

.formio-component-radio {
   display: flex;
   flex-wrap: wrap;
   align-items: center;

   .col-form-label {
      min-width: 60%;
   }

   .form-radio.radio {
      display: flex;
      width: 14%;
      min-width: 130px;
      justify-content: space-between;

      .form-check {
         display: flex;
         align-items: center;
         padding: 0;

         .form-check-label {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: -8px;

            input {
               display: none;
            }
            span {
               position: relative;
               min-height: 34px;
               display: block;
               padding-left: 30px;
               margin-bottom: 0;
               font-weight: normal;
               cursor: pointer;
               &:before,
               &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  margin: 4px;
                  width: 16px;
                  height: 16px;
                  transition: transform 0.28s ease;
                  border-radius: 50%;
                  border: 1px solid $primary-color-dark;
                  background-color: transparent;
               }
            }
         }

         &.radio-selected {
            span:after {
               top: 3px;
               left: 3px;
               width: 10px;
               height: 10px;
               background-color: $primary-color-dark;
            }
         }
      }
   }
}

.left-bg {
   position: absolute;
   height: 100vh;
   left: 0;

   @media (max-width: $sm-width) {
      width: 100vw;
      height: auto !important;
   }
}

.right-bg {
   position: absolute;
   height: 100vh;
   right: 0;

   @media (max-width: $sm-width) {
      display: none;
   }
}

.hide {
   display: none;
}

.show {
   display: block;
}

.alert.alert-danger {
   position: absolute;
   left: 50%;
   transform: translate(-50%, 10px);   

   @media (max-width: $sm-width) {
      width: 95vw;
      z-index: 5;
   }
}