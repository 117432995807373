@import '../../variable.scss';

.link-card-content {
   padding: 20px 30px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
   border-radius: 6px;
   margin-bottom: 25px;

   @media (max-width: $sm-width) {
      margin-bottom: 15px;
      padding: 20px;
   }

   a:hover {
      text-decoration: none;
   }

   h2 {
      margin-bottom: 20px;

      @media (max-width: $sm-width) {
         margin-bottom: 15px;
      }
   }

   .bt-delete {
      border: none;
      height: 40px;

      .dx-button-content {
         padding: 0;

         .dx-icon-trash {
            font-size: 20px;
            color: #602626;
         }
      }
   }

   .link-container {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 7px;

      textarea {
         margin: 0;
         border: 1px solid #ddd;
         border-radius: 4px 0 0 4px;
         height: 30px;
         padding: 4px 5px;
         flex: 1;
         resize: none;
      }

      .dx-button {
         height: 30px;
         border-radius: 0 4px 4px 0;
         border-left: none;
      }
   }
}
