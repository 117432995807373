@import '../../variable.scss';

.side-bar {
   .dx-toolbar-items-container {
      background-color: $primary-color;

      .dx-toolbar-before {
         padding-left: 13px;
      }
   }

   .dx-treeview-node-container {
      .dx-treeview-node {
         padding-left: 0;

         &.dx-state-selected {
            background-color: rgba(255, 255, 255, 0.2);

            .dx-item.dx-treeview-item {
               background: transparent;

               .dx-item-content .item {
                  border-left: 4px solid #eeb3ab;
                  padding-left: 0;
               }
            }
         }

         * {
            color: white;
            padding: 0;
         }

         .dx-item.dx-treeview-item {
            display: flex;
            align-items: center;
            font-weight: bold;

            .dx-item-content {
               height: 60px;
               width: 100%;

               @media (max-width: $sm-width) {
                  height: 50px;
               }

               .item {
                  display: flex;
                  align-items: center;
                  padding-left: 4px;
                  height: 60px;
                  width: 280px;
                  font-size: 18px;

                  @media (max-width: $sm-width) {
                     height: 50px;
                  }

                  &.node-focused {
                     background-color: rgba(255, 255, 255, 0.2);
                     border-left: 4px solid #eeb3ab;
                     padding-left: 0px;
                  }

                  &:hover {
                     background-color: rgba(255, 255, 255, 0.2);
                     border-left: 4px solid #eeb3ab;
                     padding-left: 0px;
                  }

                  img {
                     width: 24px;
                     height: 24px;
                     margin-left: 27px;
                     margin-right: 31px;
                  }
               }
            }

            @media (max-width: $sm-width) {
               height: 50px;
            }

            &.dx-state-hover {
               background-color: rgba(255, 255, 255, 0.2);
            }

            .dx-icon {
               width: 80px;
               height: 24px;
               font-size: 24px;
               margin-top: 7px;
               margin-left: -4px;
            }

            span {
               font-size: 18px;

               @media (max-width: $sm-width) {
                  font-size: 15px;
               }
            }
         }
         .dx-treeview-toggle-item-visibility {
            right: 10px;
            left: unset;
            top: 15px;

            @media (max-width: $sm-width) {
               top: 11px;
            }
         }
      }
   }

   li[aria-level='2'].dx-treeview-node-is-leaf {
      .dx-item.dx-treeview-item .dx-item-content .item {
         padding-left: 84px;

         &:hover {
            padding-left: 80px;
         }
         &.node-focused {
            padding-left: 80px;
         }
      }
   }

   .dx-treeview-node-container .dx-treeview-node.dx-state-selected li[aria-level='2'].dx-treeview-node-is-leaf {
      .dx-item.dx-treeview-item .dx-item-content .item {
         padding-left: 84px;
      }
   }

   li[aria-expanded='true'].dx-state-selected {
      ul.dx-treeview-node-container-opened {
         .dx-item.dx-treeview-item .dx-item-content a.item {
            border: none;
         }
      }
   }
}
