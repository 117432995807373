@import '../../variable.scss';

.calendar-page {
   .responsive-padings {
      padding: 40px 60px;

      @media (max-width: $sm-width) {
         padding: 30px 20px;
      }
   }

   span.CronofyAgenda__nav-date__number {
      font-size: 30px;
   }

   .CronofyAgenda__nav-date {
      color: #602626;
   }

   .CronofyAgenda__today {
      background-color: #602626;
      color: white;
      padding: 10px;
      border-radius: 5px;

      &:hover {
         background-color: #602626;
         color: white;
      }
   }

   .CronofyAgenda__event {
      display: none;

      &[title*='Counseling'] {
         display: block;
         background: white;
         border: 1px solid rgba(0, 0, 0, 0.125);
         border-radius: 0.25rem;
         color: #602626;
         padding: 15px 20px;
      }
   }

   .CronofyAgenda__event__attendees--expanded {
      .CronofyAgenda__event__attendee {
         background: #602626;

         &::before {
            background: #602626;
         }
      }
   }

   .CronofyAgenda__event__location--expanded {
      .CronofyAgenda__event__location {
         border-color: #602626;
         color: #602626;
      }
   }

   .CronofyAgenda__event__meta {
      .CronofyAgenda__event__attendee {
         background: #602626;

         &::before {
            background: #602626;
         }
      }
      .CronofyAgenda__event__location {
         border-color: #602626;
         color: #602626;
      }
   }

   #calendar-viewer {
      height: calc(100vh - 322px);

      @media (max-width: $sm-width) {
         height: calc(100vh - 210px);
      }
   }
}
