@import '../../variable.scss';
.slot-container {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 100%;

   @media (min-width: $sm-width) {
      min-width: 800px;
   }
}

#cronofy-slot-picker {
   width: 100%;

   .custom-name__slots-heading {
      font-size: 20px;
      color: #602626;
   }

   .custom-name__confirmation-buttons {
      display: flex;

      @media (max-width: $sm-width) {
         flex-direction: column;
      }

      .custom-name__confirmation-button {
         background: $primary-color-dark;
         border: 1px solid $primary-color-dark;
         border-radius: 4px;
         color: white;
         font-size: 14px;
      }

      .custom-name__confirmation-button--back {
         background: #fff;
         border: 1px solid $primary-color-dark;
         border-radius: 4px;
         color: $primary-color-dark;
         font-size: 14px;
         margin-bottom: 0.5rem;
         margin-left: 0.5rem;
      }
   }
}

.hide {
   display: none;
}

#confirm-verified {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

#slot-confirm {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);

   .button-container {
      justify-content: center;
   }

   .slot-date-header {
      font-size: 20px;
   }

   .slot-comment-header {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
   }

   .slot-date {
      appearance: none;
      font-family: inherit;
      position: relative;
      display: block;
      width: 100%;
      padding: 10px;
      font-size: inherit;
      background: rgb(245, 247, 249);
      border: 1px solid rgb(216, 216, 216);
      border-radius: 4px;
      margin-bottom: 10px;
      height: 46px;
      font-size: 16px;
   }

   .slot-comment {
      width: 100%;
      height: 200px;
   }

   .button {
      padding: 8px;
      margin: 3px;
      min-width: 100px;
   }
}

.back-button.dx-button {
   margin: 10px;

   .dx-button-content {
      padding: 16px;
      min-width: 110px;
   }
}
