@import '../../variable.scss';

#confirm-area {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);

   @media (max-width: $sm-width) {
      width: 90%;
   }

   .button-container {
      justify-content: center;
   }

   .date-header {
      font-size: 20px;
   }

   .comment-header {
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
   }

   .date {
      appearance: none;
      font-family: inherit;
      position: relative;
      display: block;
      width: 100%;
      padding: 10px;
      font-size: inherit;
      background: rgb(245, 247, 249);
      border: 1px solid rgb(216, 216, 216);
      border-radius: 4px;
      margin-bottom: 10px;
      height: 46px;
      font-size: 16px;
   }

   .comment {
      width: 100%;
      height: 200px;
   }

   .button {
      padding: 8px;
      margin: 3px;
      min-width: 100px;
   }
}

.back-button.dx-button {
   margin: 10px;

   .dx-button-content {
      padding: 16px;
      min-width: 110px;
   }
}
