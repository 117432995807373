@import '../../variable.scss';

.settings-page {
   width: 100%;

   .full-height {
      display: flex;
      flex: 1;
      min-height: calc(100vh - 240px);
      padding: 40px 60px;

      @media (max-width: $sm-width) {
         padding: 20px;
         min-height: calc(100vh - 140px);
      }

      .button-container {
         margin-bottom: 40px;
         margin-top: 10px;

         a {
            min-width: 100px;
            min-width: 100px;
            height: 20px;
            padding: 9px 20px;
            color: $primary-color-dark;
            background: white;
            text-decoration: none;
            font-family: 'quasimoda';
            font-size: 14px;
            line-height: 24.5px;
            border: 1px solid $primary-color-dark;

            &:first-child {
               border-right: none;
               border-radius: 4px 0 0 4px;
            }

            &:last-child {
               border-radius: 0 4px 4px 0;
            }

            &.focused {
               background-color: $primary-color-dark;
               color: white;
            }
         }
      }
      .contents-container {
         display: flex;
         width: 100%;
         height: 100%;
         flex-direction: column;
         margin: 20px 40px;
         padding: 40px;

         @media (max-width: $sm-width) {
            margin: 0;
            padding: 20px;
         }

         .empty-label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $sm-width) {
               flex-direction: column;
            }

            p {
               margin-bottom: 0;
            }

            .create-button {
               border: none;
               box-shadow: none;
               color: #e37871;
               text-transform: capitalize;
               margin-bottom: 1px;

               &:hover {
                  background-color: transparent;
               }

               &:focus {
                  background-color: transparent;
               }

               span {
                  text-transform: capitalize;
               }
            }
         }
      }
   }

   .spinner {
      width: 50px;
      height: 50px;
   }
}
