@import '../../variable.scss';

.back-to-button {
   color: #e37871;
   display: flex;
   align-items: center;
   margin-bottom: 20px;
   margin-top: -16px;
   width: fit-content;

   @media (max-width: $sm-width) {
      margin-top: -5px;
   }

   &:hover {
      color: #e37871;
      text-decoration: none;
   }

   i {
      margin-right: 5px;
   }
}

.student-detail-content {
   padding: 20px 30px;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
   border-radius: 6px;
   height: 100%;
   min-height: calc(100vh - 268px);

   .wrapper-content {
      min-height: calc(100vh - 310px);
   }

   p {
      @media (max-width: $sm-width) {
         margin-bottom: 5px;
      }
   }
   .quiz {
      color: #e37871;
      cursor: pointer;
   }
   #document-section {
      .document-item {
         padding: 10px 15px 0 15px;
         margin-bottom: 10px;

         .file_url {
            color: #e37871;
            text-decoration: none;
         }

         .bottom-line {
            display: flex;
            justify-content: space-between;
         }
      }
   }
}
