$primary-color:      #a28089;
$primary-color-dark: #602626;
$white-color:        #ffffff;
$white-color-dark:   #F8F7F9;
$black-color:        #333333;
$black-color-light:  #6a6a6a;


$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;
