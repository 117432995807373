.account-option {
   .dx-buttongroup-item {
      border: none;
   }
}

.dx-item.dx-list-item {
   text-align: center;
}

div[title='Subscription:'].dx-item.dx-list-item {
   .dx-item-content.dx-list-item-content {
      font-weight: bold;
      padding-left: 0;
   }

   .dx-list-item-badge-container {
      .dx-list-item-badge.dx-badge {
         background: transparent;
         color: black;
         margin-top: 3px;
         font-size: 14px;
      }
   }
}

div[title='Log Out'].dx-item.dx-list-item {
   color: #e37871;
}
