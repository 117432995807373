@import '../../variable.scss';

.calendars-page {
   span.CronofyAgenda__nav-date__number {
      font-size: 30px;
   }

   .connector-wrapper {
      padding: 8px 0px;

      a.connect-button {
         padding: 7px 18px 8px;
         background-color: #602626;
         border-radius: 4px;
         color: white;

         &:hover {
            text-decoration: none;
         }
      }
   }

   .status-bar {
      display: inline-flex;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 8px;
      padding: 7px 15px;
      margin: 8px 0px;

      @media (max-width: $sm-width) {
         width: 100%;
         padding: 5px 8px;
         flex-direction: column;
      }

      .item {
         display: flex;
         align-items: center;
         @media (max-width: $sm-width) {
            width: 100%;
            justify-content: space-between;
         }
         span {
            margin-right: 50px;
            @media (max-width: $sm-width) {
               margin-right: 0;
            }

            &.provider {
               text-transform: capitalize;
            }

            &.email {
               margin-right: 150px;
               @media (max-width: $sm-width) {
                  margin-right: 0;
               }
            }
         }
      }

      .remove-button {
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 20px;

         .dx-button-content {
            padding: 0;
         }

         i {
            font-size: 8px;
         }
      }
   }

   #cronofy-availability-rules {
      margin-top: 30px;

      @media (max-width: $sm-width) {
         width: calc(100vw - 80px);
         margin-top: 20px;
      }

      .cronofy-calendar-availability {
         border: 1px solid #cdcdcd;
         border-radius: 6px;
         padding: 15px;
         padding-bottom: 20px;

         @media (max-width: $sm-width) {
            padding: 0;
            padding-right: 5px;
         }
      }

      .cronofy-calendar-availability__calendars {
         display: none;
      }

      .cronofy-calendar-availability__legend {
         @media (max-width: $sm-width) {
            justify-content: center;
         }

         .cronofy-calendar-availability__legend-item {
            align-items: center;
         }
      }

      .cronofy-calendar-availability__timezone {
         font-size: 14px;
         justify-content: center;
      }

      .cronofy-calendar-availability__submit {
         background-color: #602626;
         border-radius: 4px;

         @media (max-width: $sm-width) {
            margin: auto;
            margin-bottom: 15px;
            font-size: 14px;
         }
      }
   }
}
