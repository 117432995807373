@import '../../variable.scss';

.questionForm {
   .formio-form {
      width: 506px;
      padding: 68px 98px 75px 98px;
      position: absolute;
      top: 213px;
      left: 50%;
      transform: translateX(-50%);
      background: #f8f7f9;
      border: 1px solid #ddd;
      border-radius: 4px;

      .btn-primary {
         min-width: 120px;
      }
      .list-group.list-group-striped {
         .col-md-9 {
            max-width: 50%;
         }
         .col-md-2 {
            max-width: 50%;
            flex: 1;
         }
      }
      .btn-wizard-nav-cancel {
         display: none;
      }

      .list-inline {
         text-align: right;
      }

      .formio-component-previos {
         position: absolute;
         right: 132px;

         .btn {
            font-family: Quasimoda;
            font-size: 16px;
         }
      }

      .form-check-label {
         width: 100%;
      }

      @media (max-width: $sm-width) {
         width: calc(100vw - 40px);
         padding: 27px;
         right: 20px;
         top: 80px;
      }

      .pagination {
         display: none;
      }

      .formio-component-radio .form-radio.radio {
         flex-direction: column;
         width: 100%;
      }
   }

   .form-group {
      > label {
         font-size: 17px;
         margin-bottom: 20px;
      }
   }

   .formio-component-selectboxes {
      .form-check {
         padding: 0;
         input {
            display: none;

            + span {
               display: block;
               width: 100%;
               background-color: #fff;
               padding: 8px 16px;
               border: 1px solid #cdcdcd;
               margin-bottom: 16px;
               border-radius: 4px;

               &:hover {
                  background: #cfe3f7;
                  cursor: pointer;
               }

               @media (max-width: $sm-width) {
                  margin-bottom: 8px;
               }
            }

            &:checked {
               + span {
                  background: #cfe3f7;
               }
            }
         }
      }
   }
}
