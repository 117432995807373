@import '../../variable.scss';

.header {
   background: white;
   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
   padding: 27px 25px;
   z-index: 10;

   @media (max-width: $sm-width) {
      height: 58px;
      position: fixed;
      width: 100%;
   }

   .toggle-button.dx-button {
      border: none;

      @media (max-width: $sm-width) {
         margin-left: -10px;
      }
   }
}
