@import '../../variable.scss';

.help-block {
   display: none;
}

.studentForm {
   .bt-next {
      display: none;
   }

   .card-header,
   .border {
      border: none;
      background: white;
   }

   .card {
      max-width: 600px;
      margin: auto;
   }
   .card-header {
      padding-bottom: 0;
   }
   .card-title {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 34px;
      color: $primary-color-dark;

      @media (max-width: $sm-width) {
         font-size: 22px;
      }
   }

   @media (min-width: $sm-width) {
      .bt-submit,
      .bt-next {
         width: 50%;
         margin: 0;
         position: absolute;
         bottom: -30px;
      }

      .bt-next {
         right: 80px;
      }
      .bt-submit {
         right: 0;
      }
   }

   .btn.btn-primary {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      background: #602626;
      width: 50%;
      height: 35px;
      max-width: 170px;
      border-radius: 24px;
      color: white;
      text-align: center;
      opacity: 1;
      border-color: #602626;
      font-family: Quasimoda;
      font-size: 15px;
      margin: auto;

      &:hover {
         color: white;
         text-decoration: none;
      }
   }

   .formio-component.formio-component-panel {
      padding-top: 22%;

      @media (max-width: $sm-width) {
         padding-top: 45%;
      }
   }
}
