@import '../../variable.scss';

.sidebar-container {
   .dx-toolbar-items-container {
      background-color: $primary-color;

      .dx-toolbar-before {
         padding-left: 23px;
      }
   }

   .dx-drawer-content {
      background: #f8f8f8;
      display: flex;
      flex-direction: column;
   }

   #content {
      padding: 36px 60px;
      overflow-y: scroll;
      min-height: calc(100vh - 90px);

      @media (max-width: $sm-width) {
         padding: 12px 22px;
         margin-top: 58px;
      }
   }

   .dx-toolbar .dx-toolbar-items-container {
      height: 90px;

      @media (max-width: $sm-width) {
         height: 58px;
      }
   }

   .dx-drawer-panel-content {
      background: $primary-color;
      border-radius: 0 43px 0 0;
   }

   .dx-overlay-wrapper {
      max-width: 280px;
   }

   #navigation-header {
      .toggle-button {
         border: none;
         background: $primary-color;

         .dx-button-content * {
            color: white;
         }
      }
   }
}

.dx-drawer-wrapper .dx-drawer-shader,
.dx-overlay-shader.dx-overlay-modal {
   background-color: #c4c4c4;
}

@media (max-width: $sm-width) {
   .dx-drawer-wrapper {
      .dx-overlay-content.dx-resizable {
         border-radius: 0 43px 0 0;
      }
   }
}
