@import '../../variable.scss';

.popup-creation-confirm,
.popup-delete-confirm {
   max-width: 600px;
   p {
      padding: 0 20px 20px 20px;
      margin-top: -10px;
   }

   .hide-button.primary-button {
      margin: 0 20px;
   }

   .dx-popup-content > .dx-button {
      @media (max-width: $sm-width) {
         margin-bottom: 20px;
         min-width: 70px;
      }
   }
}
