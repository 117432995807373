@import '../../variable.scss';

.getstarted {
   h2 {
      padding-top: 37%;
   }

   a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      background: #602626;
      width: 50%;
      height: 35px;
      max-width: 170px;
      border-radius: 24px;
      color: white;
      text-align: center;
      opacity: 1;
      border-color: #602626;
      font-family: Quasimoda;
      font-size: 15px;
      margin: 0 auto;

      &:hover {
         color: white;
         text-decoration: none;
      }
   }

   @media (max-width: $sm-width) {
      h2 {
         padding-top: 100%;
      }
   }
}
