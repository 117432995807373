.home-page {
   .CronofyAgenda__header {
      display: none;
   }

   .CronofyAgenda__event {
      display: none;

      &[title*='Counseling'] {
         display: block;
         background: white;
         border: 1px solid rgba(0, 0, 0, 0.125);
         border-radius: 0.25rem;
         color: #602626;
         padding: 15px 20px;
      }
   }

   .CronofyAgenda__event__attendees--expanded {
      .CronofyAgenda__event__attendee {
         background: #602626;

         &::before {
            background: #602626;
         }
      }
   }

   .CronofyAgenda__event__location--expanded {
      .CronofyAgenda__event__location {
         border-color: #602626;
         color: #602626;
      }
   }

   .CronofyAgenda__event__meta {
      .CronofyAgenda__event__attendee {
         background: #602626;

         &::before {
            background: #602626;
         }
      }
      .CronofyAgenda__event__location {
         border-color: #602626;
         color: #602626;
      }
   }
}
